import { index, pgTable, text, timestamp, uuid, varchar } from 'drizzle-orm/pg-core'
import { Type } from '@sinclair/typebox'
import type { Static } from '@sinclair/typebox'
import { relations, sql } from 'drizzle-orm'
import { userWorkspace } from './user-workspace'
import { apiKey } from './api-key'
import { tableToSchema } from './shared'

export const workspace = pgTable('workspace', {
    id: uuid('id').primaryKey().defaultRandom(),
    key: varchar('key', { length: 32 }).notNull().default(sql`SELECT md5(random()::text)`),
    title: varchar('title', { length: 256 }).notNull(),
    avatar_url: text('avatar_url'),
    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
}, table => ({
    idxWorkspaceCreatedAt: index('idx_workspace_created_at').on(table.createdAt),
}))

export const workspaceRelations = relations(workspace, ({ many }) => ({
    userWorkspace: many(userWorkspace),
    apiKey: many(apiKey),
}))

const { selectSchema, insertSchema } = tableToSchema(workspace)

export const selectWorkspaceSchema = selectSchema
export const enrichedWorkspaceSchema = Type.Composite([
    selectWorkspaceSchema,
    Type.Object({
        projectCount: Type.Optional(Type.Number()),
        promptCount: Type.Optional(Type.Number()),
        userCount: Type.Optional(Type.Number()),
    }),
])
export const createWorkspaceSchema = Type.Pick(insertSchema, ['title'])
export const updateWorkspaceSchema = Type.Partial(Type.Pick(insertSchema, ['title', 'avatar_url']))
export const fetchWorkspacesQuerySchema = Type.Partial(Type.Object({
    limit: Type.Number(),
    workspaceId: Type.String(),
    includeStats: Type.Boolean(),
}))

export type Workspace = Static<typeof enrichedWorkspaceSchema>
export type CreateWorkspacePayload = Static<typeof createWorkspaceSchema>
export type UpdateWorkspacePayload = Static<typeof updateWorkspaceSchema>
