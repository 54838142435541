import { Value } from '@sinclair/typebox/value'
import { type UpdateWorkspacePayload, type Workspace, enrichedWorkspaceSchema } from 'tela-api/src/database/schemas/workspace'

export function useWorkspaces() {
    const { api } = useAPI()
    const notification = useToast()
    const nuxtAPI = useNuxtAPI()
    const log = useLog()

    const currentWorkspace = useState<Workspace | null>('workspace', () => null)
    const availableWorkspaces = useState<Workspace[] | null>('available-workspaces', () => null)

    const lastWorkspaceId = useState<string | null>('last-workspace-id', () => null)

    if (import.meta.server) {
        const cookie = useCookie('current-workspace-id', { sameSite: 'lax' })
        lastWorkspaceId.value = cookie.value ?? null
    }

    function getWorkspace(workspaceId: string) {
        const workspace = availableWorkspaces.value?.find(w => w.id === workspaceId)
        return workspace
    }

    function setCurrentWorkspace(workspaceId: string) {
        const workspace = getWorkspace(workspaceId)

        if (workspace) {
            currentWorkspace.value = workspace

            const cookie = useCookie('current-workspace-id')
            cookie.value = workspaceId
            lastWorkspaceId.value = workspaceId
            if (import.meta.client) {
                // Store the data on the session storage, when on the client
                window.sessionStorage.setItem('session-workspace-id', workspaceId)
            }
        }
        else {
            log.error('Error setting current workspace', { workspaceId })
        }
    }

    async function fetchWorkspaces(options: { includeStats?: boolean } = {}) {
        const workspaces = await api().workspace.get({ $query: options })

        if (workspaces.error) {
            notification.add({
                title: 'Error fetching workspaces',
                description: workspaces.error.message,
                color: 'red',
            })
            return
        }

        availableWorkspaces.value = workspaces.data.map(toWorkspace)

        if (availableWorkspaces.value !== null) {
            const sessionWorkspace = import.meta.client
                ? getWorkspace(window.sessionStorage.getItem('session-workspace-id') ?? '')
                : null
            const lastWorkspace = getWorkspace(lastWorkspaceId.value ?? '')

            const resolvedWorkspaceId = sessionWorkspace?.id ?? lastWorkspace?.id ?? availableWorkspaces.value[0]?.id ?? ''

            setCurrentWorkspace(resolvedWorkspaceId)

            if (currentWorkspace.value === null)
                log.error('No current workspace found')
        }

        return availableWorkspaces.value
    }

    async function createWorkspace(title: string, avatar?: File) {
        const workspace = await api().workspace.post({
            title,
        })

        if (workspace.error) {
            throw new Error(workspace.error.message)
        }

        if (avatar) {
            const workspaceAvatar = await api().workspace[workspace.data.id].avatar.patch({ avatar })
            if (workspaceAvatar.error) {
                throw new Error(workspaceAvatar.error.message)
            }
        }

        return workspace.data
    }

    async function updateWorkspace(workspaceId: string, update: UpdateWorkspacePayload) {
        const updatedWorkspace = await api().workspace[workspaceId].patch(update)

        if (updatedWorkspace.error) {
            notification.add({
                title: 'Error updating workspace',
                description: updatedWorkspace.error.message,
                color: 'red',
            })
            return
        }

        availableWorkspaces.value = availableWorkspaces.value!.map((w) => {
            if (w.id === workspaceId)
                return toWorkspace(updatedWorkspace.data)
            return w
        })

        setCurrentWorkspace(workspaceId)
    }

    async function updateWorkspaceAvatar(workspaceId: string, avatar: File) {
        const updatedWorkspace = await api().workspace[workspaceId].avatar.patch({ avatar })

        if (updatedWorkspace.error) {
            notification.add({
                title: 'Error updating workspace avatar',
                description: updatedWorkspace.error.message,
                color: 'red',
            })
            return
        }

        availableWorkspaces.value = availableWorkspaces.value!.map((w) => {
            if (w.id === workspaceId)
                return toWorkspace(updatedWorkspace.data)
            return w
        })

        setCurrentWorkspace(workspaceId)

        return updatedWorkspace
    }

    async function fetchWorkspaceUsers(workspaceId: string) {
        const workspaceUsers = await api().workspace[workspaceId].user.get()

        if (workspaceUsers.error) {
            notification.add({
                title: 'Error fetching workspace users',
                description: workspaceUsers.error.message,
                color: 'red',
            })
            return
        }

        return workspaceUsers.data
    }

    async function inviteUserToWorkspace(workspace: Workspace, userEmail: string) {
        // Create the user
        const workspaceUser = await api().workspace[workspace.id].user.post({
            userEmail,
        })

        if (workspaceUser.error) {
            const errorDescription = workspaceUser.error.message || workspaceUser.error?.value?.message
            notification.add({
                title: 'Error inviting user to workspace',
                description: errorDescription,
                color: 'red',
            })
            return
        }

        try {
            // Send an invite email
            // TODO: Wrap this into a composable
            await nuxtAPI('/api/email/workspace-invite', {
                body: {
                    destinationAddress: userEmail,
                    workspaceTitle: workspace.title,
                },
                method: 'POST',
            })
        }
        catch (error) {
            notification.add({
                title: 'Error sending invite email',
                description: (error as Error).message ?? 'Unknown error',
                color: 'red',
            })
        }

        return workspaceUser.data
    }

    async function removeUserFromWorkspace(workspaceId: string, userEmail: string) {
        const workspaceUsers = await api().workspace[workspaceId].user.delete({
            userEmail,
        })

        if (workspaceUsers.error) {
            notification.add({
                title: 'Error removing user from workspace',
                description: workspaceUsers.error.message,
                color: 'red',
            })
        }
    }
    async function checkIfUserNeedsOnboarding(params: { redirectTo?: string } = {}) {
        watchEffect(async () => {
            if (availableWorkspaces.value !== null && !availableWorkspaces.value.length)
                navigateTo('/onboarding')
            else if (params.redirectTo)
                navigateTo(params.redirectTo)
        })
    }

    return {
        currentWorkspace,
        availableWorkspaces,
        createWorkspace,
        updateWorkspace,
        updateWorkspaceAvatar,
        fetchWorkspaces,
        fetchWorkspaceUsers,
        inviteUserToWorkspace,
        removeUserFromWorkspace,
        lastWorkspaceId,
        checkIfUserNeedsOnboarding,
        setCurrentWorkspace,
    }
}

function toWorkspace(workspace: any) {
    return Value.Convert(enrichedWorkspaceSchema, workspace) as Workspace
}
